import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../_metronic/assets/ts/_utils";
import { WithChildren } from "../_metronic/helpers";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import BulkUploadUsers from "../pages/User/BulkUploadUser";
import Manager from "../pages/Manager/Manager";
import UserDetails from "../pages/User/UserDetails";
import Support from "../pages/contactUs/Support";
import SendMail from "../pages/contactUs/sendBulkEmail";
import { NewPassword } from "../components/auth/NewPassword";
import Batch from "../pages/Batch";
import CollegeAdmin from "../pages/collegeAdmin/CollegeAdmin";
// import ProfilePage from "../pages/profile/ProfilePage";

const PrivateRoutes = (data:any) => {

  let user_type = data?.user_type;
  const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
  const Course = lazy(() => import("../pages/courses/Course"));
  const College = lazy(() => import("../pages/college/College"));
  const User = lazy(() => import("../pages/User/User"));
  const AdminUser = lazy(() => import("../pages/admin/AdminUser"));
  const Evidence = lazy(() => import("../pages/Evidence/Evidence"));
  const EvidenceDetails = lazy(() => import("../pages/Evidence/EvidenceDetails"));
  const Assignment = lazy(() => import("../pages/Assignment/Assignment"));
  const ContactUs = lazy(() => import("../pages/contactUs"));   
  const SendEmail = lazy(() => import("../pages/contactUs/sendBulkEmail"));   

  const routes = user_type.includes('superAdmin') ? [
    { pathname: "/auth/*", element: <Navigate to="/dashboard" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: "/dashboard", element: <Dashboard /> },
    { pathname: "/colleges", element: <College /> },
    { pathname: "/courses", element: <Course /> },
    { pathname: "/batch", element: <Batch /> },
    { pathname: "/evidence", element: <Evidence /> },
    { pathname: "/evidencedetails", element: <EvidenceDetails /> },
    { pathname: "/users", element: <User /> },
    { pathname: "/user-details", element: <UserDetails /> },
    { pathname: "/admin-users", element: <AdminUser /> },
    { pathname: "/assignment", element: <Assignment /> },
    { pathname: "/support", element: <Support /> },
    { pathname: "/manager", element: <Manager /> },
    { pathname: "/college-admin", element: <CollegeAdmin /> },
    { pathname: "/bulk-upload", element: <BulkUploadUsers /> },
    { pathname: "/send-bulk-mail", element: <SendEmail /> },
    { pathname: "/new-password", element: <NewPassword /> },
    { pathname: "*", element: <Navigate to="/dashboard" /> }, // <----- Redirect to Page not found if no route match
  ]
  :user_type.includes('manager') ? 
  [
    { pathname: "/auth/*", element: <Navigate to="/evidence" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: "/evidence", element: <Evidence /> },
    { pathname: "/evidencedetails", element: <EvidenceDetails /> },
    { pathname: "/send-bulk-mail", element: <SendEmail /> },
    { pathname: "/new-password", element: <NewPassword /> },
    { pathname: "*", element: <Navigate to="/evidence" /> }, // <----- Redirect to Page not found if no route match
  ]
  :user_type.includes('collegeAdmin') ? 
  [
    { pathname: "/auth/*", element: <Navigate to="/courses" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: "/courses", element: <Course /> },
    { pathname: "/assignment", element: <Assignment /> },
    { pathname: "/evidence", element: <Evidence /> },
    { pathname: "/evidencedetails", element: <EvidenceDetails /> },
    { pathname: "/new-password", element: <NewPassword /> },
    { pathname: "*", element: <Navigate to="/courses" /> }, // <----- Redirect to Page not found if no route match
  ]
  
  :[
    { pathname: "/auth/*", element: <Navigate to="/evidence" /> }, // <----- Redirect to Dashboard after success login/registartion
    // { pathname: "/dashboard", element: <Dashboard /> },
    // { pathname: "/contact-us", element: <ContactUs /> },
    { pathname: "/evidence", element: <Evidence /> },
    { pathname: "/new-password", element: <NewPassword /> },
    // { pathname: "/assignment", element: <Assignment /> },
    { pathname: "*", element: <Navigate to="/evidence" /> }, // <----- Redirect to Page not found if no route match
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {routes.map((route) => (
          <Route
            key={route.pathname}
            path={route.pathname}
            element={<SuspensedView>{route.element}</SuspensedView>}
          />
        ))}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

