import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useMutation } from "react-query";
import { KTCard } from "../../_metronic/helpers";
import { PageTitle } from "../../_metronic/layout/core";
import { getCollegeAdmin, getManager, suspendActiveAdmin } from "../../api";
import MuiTable from "../../components/table/MuiTable";
import { snackActions } from "../../helpers/SnackUtilsConfigurator";
import AddEditUser from "./AddEditCollegeAdmin";
import useDebounce from "../../hooks/useDebounce";
import ConfirmEventPopup from "../../_metronic/layout/components/common/ConfirmEventPopup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from "react-router-dom";

const CollegeAdmin = () => {
  const navigate = useNavigate();
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: "",
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [excelFileName, setExcelFileName] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const tableRef = useRef();
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  const handleCloseActivePopup = () => {
    setShowActiveDeActivePopup(false);
    setSelectedRow("");
      getUser({offset: 0, limit: 10});
  };

  const {
    mutateAsync: getCollegeAdminData,
    data: collegeAdminData,
    isLoading: isLoadingCollegeAdminData,
  } = useMutation("user-list", getCollegeAdmin);


  const { mutateAsync: suspendActive, isLoading: isSuspending } = useMutation(
    "API",
    suspendActiveAdmin,
  );

  useEffect(() => {
    getUser({offset: 0, limit: 10});
  }, []);

  const getUser = async (query: | {
    offset: number,
    limit: number,
  } | undefined,) => {
    let response = await getCollegeAdminData(query);
    if (response?.status) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.data?.offset,
          limit: response?.data?.limit,
          hasNextPage: response?.data?.hasNextPage,
          hasPrevPage: response?.data?.hasPrevPage,
          totalRowCount: response?.data?.totalDocs,
        };
      });
    }
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setSelectedRow("");
    if (isSubmit) {
      getUser({offset: 0, limit: 10});
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      sortable: false,
      renderCell: (data: any) => <span>
        {data?.row?.firstName && data?.row?.lastName
          ? `${data?.row?.firstName} ${data?.row?.lastName}`
          : "N/A"}
      </span>,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      sortable: false,
      renderCell: (data: any) => data?.row?.email ?? "N/A",
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      renderCell: (data: any) => `${data?.row?.countryCode}${data?.row?.mobileNumber}` ?? "N/A",
      flex: 1,
      sortable: false,
    },
    {
      field: "isUserActive",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (data: any) =>
        typeof data?.row?.isUserActive !== "undefined" ? (
          data?.row?.isUserActive === 1 ? (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1, color: "green" }} />
              <span>Active</span>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <CheckCircleIcon sx={{ fontSize: 15, mr: 1 }} color="disabled" />
              <span>Suspended</span>
            </div>
          )
        ) : (
          "N/A"
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => (
        <Box display="flex" alignItems="center">
          <Tooltip title="Make Admin User Active / Suspend">
            <ManageAccountsIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowActiveDeActivePopup(true);
              }}
              sx={{ fontSize: 22, mr: 1.7, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
          <Tooltip title="Edit">
            <DriveFileRenameOutlineIcon
              onClick={() => {
                setSelectedRow(data.row);
                setShowAddEditModal(true);
              }}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
          </Tooltip>
        </Box>
      ),
    },
  ];


  const activeDeactive = async () => {
    let bodyParams = {
      userId: selectedRow?._id ?? "",
      flag: selectedRow?.isUserActive === 1 ? true : false,
    };

    let response = await suspendActive(bodyParams);
    if (response?.status) {
      snackActions.success(response?.message ?? "Edited Successfully!");
      handleCloseActivePopup();
    } else {
      snackActions.error(response?.message ?? "Something went wrong!");
    }
  };

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query : any = {
      offset: pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? "",
    };
    getUser(query);
  };

  const bulkUpload = (e: any) => {
    setSelectedFile(e?.target?.files[0] ?? "")
    setExcelFileName(e?.target?.files[0]?.name ?? "");
    e.preventDefault();
    console.log(e?.target?.files[0])
    let formData = new FormData();
    formData.append("file", selectedFile);
  }
  const handleNavigate = () => {
    navigate("/bulk-upload")
  }

  return (
    <>
      <Card className="p-6">
        <PageTitle>College Admin</PageTitle>
        <KTCard>
          <MuiTable
            addLabel="Add College Admin"
            handleAddClick={() => setShowAddEditModal(true)}
            columns={columns}
            data={collegeAdminData?.data?.docs ?? []}
            handlePagination={handlePagination}
            tableRef={tableRef}
            tableOptions={tableOptions}
            loading={isLoadingCollegeAdminData}
            isRowClickable={true}
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditUser
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}
        {showActiveDeActivePopup && (
          <ConfirmEventPopup
            showPopup={showActiveDeActivePopup}
            handleClose={handleCloseActivePopup}
            handleConfirmed={activeDeactive}
            loading={isSuspending}
            title={"Active / Suspend User"}
            message={`Are you sure, want to ${
              selectedRow?.isUserActive === 1 ? '"Suspend"' : '"Activate"'
            } this user?`}
          />
        )}
      </Card>
    </>
  );
};

export default CollegeAdmin;
